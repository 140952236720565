<template>
  <div>
    <div class="area-title">
      <p class="title">
        货物信息
      </p>
    </div>
    <div class="van-common-detail van-common-detail-button">
      <van-cell-group v-for="(item,index) in goodsList" :key="item.id">
        <div class="van-common-left">
          <span>{{ index+1 }}</span>
        </div>
        <div class="van-common-right">
          <van-cell title="品名：" :value="utils.isEffectiveCommon(item.productName)" />
          <van-cell title="单价（原币）：" :value="utils.isEffectiveCommon(item.unitPrice)" />
          <van-cell title="可提重量（KG）：" :value="utils.isEffectiveCommon(item.extractableWeight)" />
          <van-cell title="可提件数：" :value="utils.isEffectiveCommon(item.extractableNumber)" />
          <van-cell title="实际重量（KG）：" :value="utils.isEffectiveCommon(item.actualWeight)" />
          <van-cell title="实际件数：" :value="utils.isEffectiveCommon(item.actualPieceNumber)" />
        </div>
      </van-cell-group>
    </div>
    <div class="button-fixed">
      <van-button type="info" block @click="$router.back()">
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button } from 'vant'

export default {
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button
  },
  data () {
    return {
      stockContainerId: this.$route.query.stockContainerId,
      accountForm: {},
      showGoods: false,
      detailList: [],
      goodsList: [],
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: false,
      finished: false,
      refreshing: false,
      loadingSubmit: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.api.delivery.packOutCabinetInfoDetail(this.stockContainerId).then(result => {
        const resData = result.data.value
        this.goodsList = resData.goodsList
      }).catch(() => {
      })
    }
  }
}
</script>

<style>
</style>
