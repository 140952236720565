var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "van-common-detail van-common-detail-button" },
      _vm._l(_vm.goodsList, function(item, index) {
        return _c("van-cell-group", { key: item.id }, [
          _c("div", { staticClass: "van-common-left" }, [
            _c("span", [_vm._v(_vm._s(index + 1))])
          ]),
          _c(
            "div",
            { staticClass: "van-common-right" },
            [
              _c("van-cell", {
                attrs: {
                  title: "品名：",
                  value: _vm.utils.isEffectiveCommon(item.productName)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "单价（原币）：",
                  value: _vm.utils.isEffectiveCommon(item.unitPrice)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "可提重量（KG）：",
                  value: _vm.utils.isEffectiveCommon(item.extractableWeight)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "可提件数：",
                  value: _vm.utils.isEffectiveCommon(item.extractableNumber)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "实际重量（KG）：",
                  value: _vm.utils.isEffectiveCommon(item.actualWeight)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "实际件数：",
                  value: _vm.utils.isEffectiveCommon(item.actualPieceNumber)
                }
              })
            ],
            1
          )
        ])
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "button-fixed" },
      [
        _c(
          "van-button",
          {
            attrs: { type: "info", block: "" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [_vm._v(" 返回 ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }